.occasions-cta {
    height: 100%;
    position: relative;
    box-shadow: $shadow-light;

    &__bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-size: cover;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            background: map-deep-get($colors, "black", "default");
            opacity: .75;
        }
    }

    &__content {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 1.5rem 2rem;

        @include media-breakpoint-up(md) {
            padding: 2rem 2.5rem;
            max-width: 30rem;
        }
    }
}