.filter-slider {
    $elm: &;
    position: relative;
    height: .5rem;
    background: map-deep-get($colors, "gray", "dark");
    border-radius: .25rem;
    display: flex;
    touch-action: none;
    user-select: none;
    box-sizing: border-box;

    * {
        touch-action: none;
        user-select: none;
        box-sizing: border-box;
    }

    &__base, &__connects {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
    }

    &__connects {
        overflow: hidden;
        z-index: 0;
        border-radius: .25rem;
    }

    &__connect, &__origin {
        will-change: transform;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        transform-origin: 0 0;
        transform-style: flat;
    }

    &__connect {
        height: 100%;
        width: 100%;
        background: map-deep-get($colors, "brand", "primary");
    }

    &__origin {
        width: 10%;
        height: 0;
    }

    &__handle {
        backface-visibility: hidden;
        position: absolute;
        width: 1rem;
        height: 1rem;
        right: -.5rem;
        top: -.25rem;
        border-radius: 50%;
        background: map-deep-get($colors, "brand", "primary");
        cursor: grab;

        &--active {
            background: lighten(map-deep-get($colors, "brand", "primary"), 10%);
        }
    }

    &__touch-area {
        height: 100%;
        width: 100%;
    }

    &--state-tap {
        #{$elm}__connect, #{$elm}__origin  {
            transition: transform 0.3s;
        }
    }
}