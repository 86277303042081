.file-input {
    &__label {
        @extend .button;
        @extend .button--primary;
        @extend .button--icon-left;
        display: inline-flex;
        cursor: pointer;
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: auto;
            max-width: 270px;
            overflow: hidden;
            white-space: nowrap;
            position: relative;

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                width: 30px;
                right: 0;
                bottom: 0;
            }
        }
    }

    &__input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        padding: 0;
        /* stylelint-disable */
        border: none !important;
        /* stylelint-enable */
    }
}
