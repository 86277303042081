.occasion-options {
    margin-bottom: 1.25rem;
    $this: &;

    &__content {
        background: map-deep-get($colors, "black", "default");
        padding: 1.5rem;

        @include media-breakpoint-up(md) {
            padding: 2.5rem;
        }
    }

    &__title {
        @extend .h5;
        @extend .italic;
    }

    &__list {
        position: relative;
        display: flex;
        flex-flow: wrap;
        max-height: 12rem;
        overflow: hidden;
        padding-bottom: 0.5rem;
        transition: $transition-default;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 6rem;
            background: linear-gradient(0deg, map-deep-get($colors, "black", "default") 0%, rgba(24, 24, 27, 0) 100%);
        }
    }

    &__item {
        position: relative;
        width: 100%;
        padding-left: 1.25rem;
        margin-right: 1.25rem;
        margin-bottom: .5rem;
        font-size: 0.8rem;

        @include media-breakpoint-up(sm){
            width: calc(50% - 1.25rem);
        }

        @include media-breakpoint-up(md){
            width: calc(33.333% - 1.25rem);
        }

        @include media-breakpoint-up(lg) {
            width: calc(33.333% - 1.25rem);
        }

        &::before {
            content: "";
            background: map-deep-get($colors, "brand", "primary");
            width: 0.375rem;
            height: 0.375rem;
            border-radius: 50%;
            position: absolute;
            transform: translateY(-50%);
            left: 0;
            top: .75rem;
        }
    }

    &__more {
        @extend .textlink;
        @extend .textlink--scale;
        padding: 0;
    }

    &.open {
        #{$this}__list {
            max-height: none;

            &::after {
                opacity: 0;
            }
        }
    }
}
