// bootstrap
@import "bootstrap/bootstrap";

@import 'hamburgers/hamburgers';

@import 'blazy/blazy';

@import "flickity/flickity-vendor";
@import "flickity/flickity";

$aos-distance: 50px;
@import "aos/src/sass/aos";

.aos-animate {
    z-index: 1;
}