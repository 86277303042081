.before-hover-shadow {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: $shadow-hover;
        opacity: 0;
        transition: $transition-default;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }
}