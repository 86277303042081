.min-max-slider {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    margin-top: 1rem;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        align-items: center;
        order: 2;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        margin-top: 1.5rem;
    }

    &__values {
        @extend .h6;
        margin-bottom: 1rem;
        order: 1;
        white-space: nowrap;

        @include media-breakpoint-up(sm) {
            order: 2;
            margin-bottom: 0;
            margin-left: 1.5rem;
        }
    }

    &__slider {
        order: 2;
        padding-left: .25rem;
        padding-right: .25rem;
        width: 100%;

        @include media-breakpoint-up(sm) {
            order: 1;
            flex-grow: 1;
        }
    }
}