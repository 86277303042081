.contact {
    overflow: hidden;

    &__content {
        @extend .block-padding;
        position: relative;
    }

    &__col {
        margin-bottom: 1.5rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__address {
        margin-bottom: 1.5rem;
    }

    &__map {
        margin-top: 2.5rem;
        width: 100%;
        height: 400px;

        @include media-breakpoint-up(md){
            margin-top: -2.5rem;
            height: 500px;
        }
        @include media-breakpoint-up(lg){
            height: 600px;
        }
        @include media-breakpoint-up(xl){
            height: 700px;
        }
    }

    &__form {
        background: map-deep-get($colors, "black", "default");
        padding: 1rem;
        margin-top: 1.5rem;

        @include media-breakpoint-up(md){
            margin-top: 2.5rem;
            padding: 2rem 2rem 0.8rem;
        }
    }

    .form__button {
        background: map-deep-get($colors, "brand", "primary");
    }
}
