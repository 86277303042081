input, select {
    background: map-deep-get($colors, "white", "default");
    border-radius: 0;
    border: 1px solid map-deep-get($colors, "gray", "medium");
    color: $body-color;
    font-family: $font-family-base;
    min-height: 3rem;
    line-height: normal;
    padding: 1.25rem 1rem .75rem;
    transition: $transition-default;
    width: 100%;

    &:focus {
        background: map-deep-get($colors, "white", "default");
        outline: none;
        border: 1px solid map-deep-get($colors, "brand", "primary");
    }
}

textarea {
    background: map-deep-get($colors, "white", "default");
    border: 1px solid map-deep-get($colors, "gray", "medium");
    border-radius: 0;
    color: $body-color;
    font-family: $font-family-base;
    min-height: 7.5rem;
    padding: 1.25rem 1rem 1rem;
    line-height: normal;
    resize: vertical;
    transition: border .3s ease-in-out;
    width: 100%;

    &:focus {
        background: map-deep-get($colors, "white", "default");
        outline: none;
        border: 1px solid map-deep-get($colors, "brand", "primary");
    }
}

select {
    appearance: none;
    background: url("../images/icons/angle/down.svg");
    background-size: 1.25rem;
    background-position: calc(100% - .75rem) center;
    background-repeat: no-repeat;
    background-color: map-deep-get($colors, "white", "default");

    &::-ms-expand {
        display: none;
    }

    &:focus {
        background: url("../images/icons/angle/down.svg");
        background-size: 1.25rem;
        background-position: calc(100% - .75rem) center;
        background-repeat: no-repeat;
        background-color: map-deep-get($colors, "white", "default");
    }
}

//Radio Button styling

.radio-wrapper {
    display: flex;
    align-items: center;
    $radioWrapper: &;

    &__input-wrapper {
        position: relative;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: .25rem;
        margin-right: 1rem;
        float: left;
        cursor: pointer;
        flex-shrink: 0;

        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            background-color: map-deep-get($colors, "white", "default");
            border: 1px solid map-deep-get($colors, "black", "default");
            border-radius: 50%;
            display: block;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }

    &__input-dot {
        position: absolute;
        left: .25rem;
        top: .25rem;
        z-index: 2;
        border-radius: 50%;
        background-color: map-deep-get($colors, "brand", "primary");
        width: .75rem;
        height: .75rem;
        opacity: 0;
    }

    // Default input styling uit Back-End heeft geen class
    /* stylelint-disable */
    &__input, input {
        line-height: 1;
        position: absolute;
        left: -999rem;

        &:checked {
            border: 1px solid map-deep-get($colors, "brand", "primary");

            + #{$radioWrapper}__input-dot {
                opacity: 1;

                &::before {
                    border-color: map-deep-get($colors, "brand", "primary");
                }
            }
        }
    }

    &__label, label {
        font-size: 1rem;
        cursor: pointer;
        font-weight: 400;
    }
    /* stylelint-enable */
}

.checkbox-wrapper {
    display: flex;
    align-items: start;
    margin-bottom: 0;
    $checkboxWrapper: &;

    &__input-wrapper {
        position: relative;
        cursor: pointer;
        margin-bottom: 0;
        flex-shrink: 0;
        line-height: 1.75rem;
    }

    &__input-dot {
        width: 1.5rem;
        height: 1.5rem;
        background-color: map-deep-get($colors, "white", "default");
        border: 1px solid map-deep-get($colors, "black", "default");
        margin-right: rem-calc(20);
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
            opacity: 0;
            fill: map-deep-get($colors, "brand", "primary");
            width: 1.25rem;
            height: 1.25rem;
        }
    }

    // Default input styling uit Back-End heeft geen class
    /* stylelint-disable */
    &__input, input {
        line-height: 1;
        position: absolute;
        left: -999rem;

        &:checked {
            + #{$checkboxWrapper}__input-dot {
                svg {
                    opacity: 1;
                }
            }
        }
    }

    &__label, label {
        cursor: pointer;
        align-self: center;
        font-weight: 400;
        text-transform: none;
    }
    /* stylelint-enable */
}
