.services-slider {
    $this: &;

    .flickity-page-dots {
        right: 2rem;
        width: 10rem;
        height: 1.25rem;
        bottom: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 3rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 4rem;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 5rem;
        }

        .dot {
            background: map-deep-get($colors, "white", "default");
        }
    }

    .flickity-prev-next-button.next,
    .flickity-prev-next-button.previous {
        position: absolute;
        right: 0;
        top: inherit;
        width: 1.25rem;
        height: 1.25rem;
        padding: 0;
        bottom: 100%;
        background: none;
        border-radius: 0;
        transition: $transition-default;
        margin-bottom: 3rem;
        transform: translate(0);

        @include media-breakpoint-up(md) {
            margin-bottom: 4rem;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 5rem;
        }

        &:hover {
            transform: translate(0) scale(1.2);
        }

        svg {
            fill: map-deep-get($colors, "white", "default");
            height: 1.25rem;
            width: 1.25rem;
            margin: 0;
            left: 0;
            top: 0;
        }
    }

    .flickity-prev-next-button.previous {
        left: inherit;
        right: 12.75rem;
    }

    .flickity-viewport {
        height: 100%;
        width: 100%;
        overflow: visible;
    }

    .carousel-cell {
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: 50%;
        }

        @include media-breakpoint-up(md) {
            width: 33.333%;
        }
    }
}
