.video {
    &__play {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        bottom: 4rem;
        right: rem-calc(5);

        @include media-breakpoint-up(md) {
            right: rem-calc(40);
        }

        @include media-breakpoint-up(xl) {
            bottom: 11rem;
            right: rem-calc(300);
        }
    }

    &__play-icon {
        border: solid transparent;
        height: 0;
        width: 0;
        pointer-events: none;
        border-left-color: map-deep-get($colors, "brand", "primary");
        border-width: 15px 20px 15px 25px;
    }
}
