.service-packages {
    @extend .block-padding--bottom;
    background: map-deep-get($colors, "black", "default");

    &__text {
        margin-bottom: 3rem;

        @include media-breakpoint-up(md) {
            margin-top: 2rem;
            margin-bottom: 0;
        }
    }

    &__form {
        background: map-deep-get($colors, "white", "default");
        padding: 40px;
        color: map-deep-get($colors, "black", "default");
    }

    &__form-title {
        @extend .h4;
        @extend .italic;
        color: map-deep-get($colors, "black", "default");
    }

    &__row {
        @extend .block-padding--bottom;
        margin-right: 0;
        margin-left: 0;
        transform: translateY(-1.5rem);

        @include media-breakpoint-up(md) {
            transform: translateY(-2.5rem);
        }
    }

    &__col {
        padding: 0;
    }
}
