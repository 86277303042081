.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99; // z-index is om te zorgen dat deze boven de barba laag zit. Hierdoor wordt voor het oog alleen de content vervangen
    height: map_get($header-height, "medium");
    transition: $transition-default;
    padding: 1rem 10px;
    $header: &;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 120px;
        background: linear-gradient(180deg, map-deep-get($colors, "black", "default") 0%, rgba(24, 24, 27, 0.8) 40%, rgba(24, 24, 27, 0) 95%);
        pointer-events: none;
        opacity: 0.7;
    }

    @include media-breakpoint-up(md) {
        background-color: transparent;
        height: map_get($header-height, "medium");
        overflow: visible;
        padding: 0 10px;
    }

    @include media-breakpoint-up(xl) {
        height: map_get($header-height, "large");
    }

    &--scrolled {
        margin-top: 0;

        #{$header}__logo {
            height: 2rem;
        }
    }

    &--expanded {
        background: map-deep-get($colors, "black", "default");
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;
    }

    &__logo {
        transition: $transition-default;
        height: 1.75rem;
        display: block;

        @include media-breakpoint-up(sm) {
            height: 2rem;
        }

        @include media-breakpoint-up(md) {
            height: 2.5rem;
        }

        svg {
            width: auto;
            height: 100%;
        }
    }

    &__menu-toggle {
        float: right;
        position: relative;
    }
}
