.service-package {
    @extend .before-hover-shadow;
    box-shadow: $shadow-light;
    padding-top: 125%;
    background: map-deep-get($colors, "gray", "dark");
    transition: 0.3s ease;
    $elm: &;

    &__image, &__overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &__image {
        object-fit: cover;
        font-family: "object-fit: cover;";
    }

    &__overlay {
        background: map-deep-get($colors, "black", "default");
        opacity: .5;
        transition: $transition-default;
    }

    &__content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__title {
        @extend .h3;
        @extend .italic;
        margin-bottom: 0;
        text-align: center;
        width: 100%;
    }

    &__price {
        font-size: 2rem;
        font-style: italic;
        width: 100%;
        text-align: center;
    }

    &__description {
        position: relative;
        text-align: center;
        width: 100%;
        line-height: 0;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        margin-bottom: 0;
        transition: $transition-default;
    }

    &__button {
        @extend .button;
        @extend .button--white;
        max-height: 0;
        opacity: 0;
        transform: translateY(5rem);
        margin-right: 0;
        overflow: visible;
        transition: $transition-default;
    }

    &:hover {
        transform: scale(1.025);
        z-index: 10;

        #{$elm}__overlay {
            opacity: 0.75;
        }

        #{$elm}__description {
            max-height: 100%;
            opacity: 1;
            line-height: 1.5;
            margin-bottom: 1.5rem;
        }

        #{$elm}__button {
            max-height: 100%;
            opacity: 1;
            transform: translateY(0);
        }
    }
}