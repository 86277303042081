.hero {
    position: relative;
    display: flex;
    align-items: flex-end;

    @include media-breakpoint-up(md) {
        height: 66.667vw;
    }

    @include media-breakpoint-up(lg) {
        height: 50vw;
    }

    &__video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;

        video {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            font-family: "object-fit: cover;";
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: $gradient-default;
        }
    }

    &__graphic-wrapper {
        position: absolute;
        opacity: 1;
        right: -3.25rem;
        bottom: -3.125rem;
        width: 13rem;
        height: 12.5rem;

        @include media-breakpoint-up(md) {
            width: 26rem;
            height: 25rem;
            right: -6.5rem;
            bottom: -6.25rem;
        }
    }

    &__graphic {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: .625;

        svg {
            width: 100%;
            height: 100%;
            fill: map-deep-get($colors, "brand", "primary");
        }
    }

    &__title {
        @extend .italic;
    }

    &__intro-text {
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 2.5rem;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        margin-top: 10rem;
        margin-bottom: 3rem;

        @include media-breakpoint-up(md) {
            margin-top: 5rem;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 7.5rem;
        }

        @include media-breakpoint-up(xxl) {
            margin-bottom: 10rem;
        }
    }

    &__button {
        margin-bottom: 1.5rem;
    }

    &__textlink {
        margin-left: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-left: 2rem;
        }
    }
}
