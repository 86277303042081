.team-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    z-index: 1;

    &__col {
        margin-bottom: 1.25rem;
    }
}