.button {
    @extend .before-hover-shadow !optional;
    padding: 0.75rem 1.25rem;
    line-height: 1.5rem;
    font-size: .875rem;
    background-color: map-deep-get($colors, "gray", "medium");
    margin-bottom: .5rem;
    margin-right: .5rem;
    color: map-deep-get($colors, "white", "default");
    border: 1px solid transparent;
    transition: $transition-default;
    font-weight: 900;
    letter-spacing: .025em;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;

    @include media-breakpoint-up(sm) {
        padding: 0.875rem 1.5rem;
    }

    &:hover {
        color: map-deep-get($colors, "white", "default");
        text-decoration: none;
        transform: scale(1.025);
    }

    svg {
        width: 1.5rem;
        height: 1.5rem;
        transition: $transition-default;
        fill: map-deep-get($colors, "white", "default");
    }

    &--small {
        font-size: .875rem;
        padding: .625rem 1.25rem;
        line-height: 1.25rem;

        svg {
            width: 1.25rem;
            height: 1.25rem;
        }
    }

    &--wide {
        width: 100%;
        justify-content: center;
    }

    &--badge {
        padding: .625rem;
        border-radius: 50%;
        margin-right: .5rem;
        margin-bottom: .5rem;

        &::before {
            border-radius: 50%;
        }

        svg {
            height: 1.25rem;
            width: 1.25rem;
        }
    }

    /* stylelint-disable */
        &--nomargin {
            margin: 0 !important;
        }
    /* stylelint-enable */

    &--icon-left {
        svg {
            margin-left: -.25em;
            margin-right: 1em;
        }
    }

    &--icon-right {
        svg {
            margin-right: -.25em;
            margin-left: 1em;
        }
    }

    &--primary {
        background-color: map-deep-get($colors, "brand", "primary");
    }

    &--secondary {
        background-color: map-deep-get($colors, "brand", "secondary");
    }

    &--black {
        background: map-deep-get($colors, "black", "default");
    }

    &--white {
        background: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "black", "default");

        svg {
            fill: map-deep-get($colors, "black", "default");
        }

        &:hover {
            color: map-deep-get($colors, "black", "default");
        }
    }

    &--white-ghost {
        border-color: map-deep-get($colors, "white", "default");
        background: none;

        svg {
            fill: map-deep-get($colors, "white", "default");
        }

        &:hover {
            background: map-deep-get($colors, "white", "default");
            color: map-deep-get($colors, "black", "default");

            svg {
                fill: map-deep-get($colors, "black", "default");
            }
        }
    }
}
