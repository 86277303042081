.services-card {
    @extend .before-hover-shadow;
    box-shadow: $shadow-light;
    padding-top: 120%;
    transition: $transition-default;
    $this: &;

    &:hover {
        transform: scale(1.025);
        z-index: 10;

        #{$this}__content {
            &::before {
                opacity: 0.8;
            }
        }

        #{$this}__title, #{$this}__logo {
            margin-bottom: 1.5rem;
        }

        #{$this}__paragraph {
            max-height: 100%;
            opacity: 1;
            line-height: 1.5;
            margin-bottom: 1.5rem;
        }

        #{$this}__button {
            max-height: 100%;
            opacity: 1;
            transform: translateY(0);
        }
    }

    &__logo {
        height: 2rem;
        font-size: 2rem;
        margin-bottom: 0;
        position: relative;
        display: flex;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            height: 2.5rem;
            font-size: 2.5rem;
        }

        @include media-breakpoint-up(xl) {
            height: 3rem;
            font-size: 3rem;
        }

        svg {
            height: 2rem;
            width: 3.9rem;

            @include media-breakpoint-up(md) {
                height: 2.5rem;
                width: 4.875rem;
            }

            @include media-breakpoint-up(xl) {
                height: 3rem;
                width: 5.85rem;
            }
        }

        &::before, &::after {
            content: "";
            display: block;
            height: .2em;
            width: .8em;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            top: .356em;
        }

        &--before {
            align-items: flex-end;

            &::before {
                background-image: url("/images/before-before.svg");
                right: calc(100% - .25rem);
                background-position-x: right;
            }

            &::after {
                background-image: url("/images/before-after.svg");
                left: calc(100% + .25rem);
            }

            #{$this}__logo-text {
                top: .025em;
                margin-left: -.125rem;
            }
        }

        &--after {
            align-items: flex-start;

            &::before {
                background-image: url("/images/after-before.svg");
                right: 100%;
                background-position-x: right;
                margin-right: .125rem;
            }

            &::after {
                background-image: url("/images/after-after.svg");
                left: 100%;
                margin-left: .125rem;
            }

            #{$this}__logo-text {
                bottom: .08em;
                margin-right: -.125rem;
            }
        }
    }

    &__logo-text {
        display: inline-flex;
        position: relative;
        font-family: AxxiFont, sans-serif;
        font-weight: 800;
        font-style: italic;
        transform: skewX(-4deg);
        font-size: .925em;
        line-height: .8;
    }

    &__img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        font-family: "object-fit: cover;";
    }

    &__content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &::before {
            content: "";
            background: map-deep-get($colors, "gray", "extra-dark");
            opacity: 0.5;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transition: $transition-default;
        }
    }

    &__title {
        @extend .h4;
        @extend .italic;
        position: relative;
        margin-bottom: 0;
        transition: $transition-default;
    }

    &__paragraph {
        position: relative;
        text-align: center;
        line-height: 0;
        max-height: 0;
        opacity: 0;
        width: 100%;
        overflow: hidden;
        margin-bottom: 0;
        transition: $transition-default;
    }

    &__button {
        @extend .button;
        @extend .button--primary;
        @extend .button--icon-right;
        max-height: 0;
        opacity: 0;
        transform: translateY(5rem);
        transition: $transition-default;
        margin-bottom: 0;
    }
}
