.carmeleon-modal {
    &__container {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        z-index: 100;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0.001;
        pointer-events: none;
        transition: $transition-default;

        &.open {
            opacity: 1;
            pointer-events: initial;
        }

        iframe {
            width: 100%;
        }
    }

    &__content {
        position: relative;
        margin: 1rem;
        padding: 2rem;
        background: map-deep-get($colors, "black", "default");
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include media-breakpoint-up(sm) {
            align-items: center;
            justify-content: space-between;
        }

        @include media-breakpoint-up(md) {
            flex-direction: row;
            margin-bottom: 1.5rem;
        }
    }

    &__title {
        @extend .h4;
        @extend .italic;
        order: 2;

        @include media-breakpoint-up(sm) {
            order: 1;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    &__close {
        @extend .textlink;
        @extend .textlink--white;
        @extend .textlink--scale;
        order: 1;
        align-self: flex-end;
        margin-bottom: 1.25rem;

        @include media-breakpoint-up(sm) {
            order: 2;
            align-self: center;
            margin-bottom: 0;
        }
    }
}
