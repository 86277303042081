.about {
    position: relative;
    background: map-deep-get($colors, "black", "default");

    @include media-breakpoint-up(md) {
        margin: -3.75rem 0;
    }

    &__image {
        height: 100vw;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @include media-breakpoint-up(md) {
            position: absolute;
            height: auto;
            left: 0;
            top: 0;
            bottom: 0;
            width: 40%;
        }

        @include media-breakpoint-up(lg) {
            width: 50%;
        }
    }

    &__text {
        margin-top: 3rem;
        margin-bottom: 3rem;

        @include media-breakpoint-up(sm) {
            margin-top: 5rem;
            margin-bottom: 5rem;
        }

        @include media-breakpoint-up(md) {
            margin-top: 7.5rem;
            margin-bottom: 7.5rem;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 10rem;
            margin-bottom: 10rem;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 15rem;
            margin-bottom: 15rem;
        }
    }
}
