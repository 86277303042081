.highlighted {
    position: relative;
    z-index: 2;
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include media-breakpoint-up(md) {
        padding-top: 5rem;
        padding-bottom: 0;
    }

    &__header {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__title {
        @extend .h3;
        @extend .italic;
        margin-bottom: 0;
    }

    &__list {
        margin-top: 2rem;
    }
}
