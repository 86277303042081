.team-card {
    background: map-deep-get($colors, "gray", "extra-dark");
    box-shadow: $shadow-light;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__image {
        position: relative;
        padding-bottom: 100%;
        overflow: hidden;

        img, video {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding: 1.5rem 2rem;

        @include media-breakpoint-up(md) {
            padding: 2rem 2.5rem;
        }
    }

    &__name {
        @extend .h5;
        margin-bottom: .25rem;
        width: 100%;
    }

    &__function {
        display: block;
        font-size: 0.8rem;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.05em;
        width: 100%;
        text-align: center;
        color: map-deep-get($colors, "brand", "primary");
        margin-bottom: 1.5rem;
    }

    &__description {
        flex-grow: 1;
        width: 100%;
    }

    &__buttons {
        display: flex;
        justify-content: center;
    }

    &__button {
        @extend .button;
        @extend .button--badge;
        @extend .button--primary;
        margin: 0 .25rem;
    }
}
