.block-padding {
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include media-breakpoint-up(md) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    &--top {
        padding-top: 3rem;

        @include media-breakpoint-up(md) {
            padding-top: 4rem;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 5rem;
        }
    }

    &--bottom {
        padding-bottom: 3rem;

        @include media-breakpoint-up(md) {
            padding-bottom: 4rem;
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: 5rem;
        }
    }
}