.breadcrumb {
    display: flex;
    margin-bottom: .75rem;

    &__item {
        position: relative;
        font-weight: 900;
        font-size: .75rem;
        letter-spacing: .05em;
        margin: 0;
        text-transform: uppercase;

        &::after {
            content: "/";
            position: relative;
            font-size: 0.75rem;
            margin: 0 .5rem;
        }

        a {
            color: map-deep-get($colors, "white", "default");
            text-decoration: none;

            &:hover {
                text-decoration: none;
                opacity: .75;
            }
        }

        &--current {
            opacity: .5;
        }

        &:last-child {
            &::after {
                content: none;
            }
        }
    }
}
