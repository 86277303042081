.occasion-categories {
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    $this: &;

    &__label {
        font-size: 1.5rem;
        line-height: 1;
        font-weight: bold;
        font-style: italic;
        padding-right: 1.5rem;

        @include media-breakpoint-down(sm){
            width: 100%;
            margin-bottom: 1rem;
        }
    }

    &__list {
        display: flex;
        box-shadow: 0 0 0 1px map-deep-get($colors, "white", "default");
        border-radius: 1.2rem;

        @include media-breakpoint-down(sm){
            flex-direction: column;
            width: 100%;
        }

        &:hover {
            #{$this}__link {
                background: transparent;
                color: map-deep-get($colors, "white", "default");
            }
        }
    }

    &__link {
        display: block;
        padding: 0.8rem 1.2rem;
        font-size: 0.8rem;
        line-height: 1;
        font-weight: bold;
        color: map-deep-get($colors, "white", "default");
        border-radius: 1.2rem;
        letter-spacing: 0.025em;
        border: 1px solid transparent;
    }

    &__categorie {
        &.active, &:hover {
            #{$this}__link {
                background: map-deep-get($colors, "white", "default");
                color: map-deep-get($colors, "black", "default");
                border: 1px solid map-deep-get($colors, "white", "default");
                text-decoration: none;
            }
        }
    }
}
