.service {
    &__intro {
        @extend .block-padding--top;
        padding-bottom: 4.5rem;

        @include media-breakpoint-up(md) {
            padding-bottom: 6.5rem;
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: 7.5rem;
        }
    }

    &__textlink {
        @extend .textlink;
        @extend .textlink--back;
        margin-bottom: 2rem;
    }
}