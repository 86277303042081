.footer {
    color: map-deep-get($colors, "white", "default");
    z-index: 15;
    flex: 1 1 100%;

    &__top {
        margin-top: -1px;
        padding-bottom: 2rem;
        padding-top: 2rem;
        border-top: 1px solid map-deep-get($colors, "gray", "extra-dark");
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__contact {
        display: flex;
        align-items: center;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }

    &__certificates{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 1.5rem;

        img{
            width: 50px;
        }
    }

    &__button {
        @extend .button;
        @extend .button--icon-left;
        margin-right: 0;

        @include media-breakpoint-up(sm) {
            padding: 0.75rem 1.25rem;
        }

        @include media-breakpoint-up(md) {
            margin-right: .5rem;
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            padding: 0.875rem 1.5rem;
        }
    }

    &__socials {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__social-title {
        margin-right: 1.5rem;

        @include media-breakpoint-up(md) {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    &__social {
        @extend .button;
        @extend .button--black;
        @extend .button--badge;
        margin-bottom: 0;
    }

    &__address {
        text-align: center;
        margin-top: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-top: 0;
            margin-left: 1rem;
            text-align: left;
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            margin-left: 1.5rem;
        }
    }

    &__bottom {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background-color: map-deep-get($colors, "gray", "extra-dark");
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
    }

    &__list-item {
        width: 100%;
        flex: 0 0 100%;
        text-align: center;
        font-size: 1rem;
        line-height: 1.5;
        color: map-deep-get($colors, "gray", "light");
        display: inline-block;
        margin: .5rem;
        text-decoration: underline;

        @include media-breakpoint-up(md) {
            display: inline-flex;
            width: auto;
            flex: 0 0 auto;
            text-align: left;
            padding-right: 10px;
        }
    }

    &__item {
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }

    &__reference {
        text-align: center;
        width: 100%;
        font-size: 1rem;
        line-height: 1.5;
        color: map-deep-get($colors, "white", "default");
        font-weight: 600;
        display: inline-block;
        margin-top: 1rem;

        @include media-breakpoint-up(md) {
            display: inline-block;
            text-align: left;
            margin-top: 0;
        }
    }

    &__webnl {
        color: map-deep-get($colors, "white", "default");

        &:hover {
            color: map-deep-get($colors, "white", "default");
        }
    }
}
