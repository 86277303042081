.page-header {
    position: relative;
    padding-top: 12.5rem;
    padding-bottom: 10rem;
    margin-bottom: -5rem;
    $elm: &;

    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: $gradient-default-full;
        }
    }

    &__graphic {
        position: absolute;
        opacity: 0.375;
        right: -3.25rem;
        bottom: -3.125rem;
        width: 13rem;
        height: 12.5rem;

        @include media-breakpoint-up(md) {
            width: 26rem;
            height: 25rem;
            right: -6.5rem;
            bottom: -6.25rem;
        }

        svg {
            fill: map-deep-get($colors, "black", "default");
        }
    }

    &__content {
        position: relative;
    }

    &__title {
        @extend .italic;
    }

    &--textpage, &--service {
        margin-bottom: 0;
        padding-bottom: 1.5rem;
        padding-top: 5rem;
        height: 75vw;
        display: flex;
        align-items: flex-end;

        @include media-breakpoint-up(sm) {
            height: 66.667vw;
        }

        @include media-breakpoint-up(md) {
            padding-bottom: 2.5rem;
            height: 50vw;
        }

        @include media-breakpoint-up(lg) {
            height: 33.333vw;
        }

        @include media-breakpoint-up(xl) {
            height: 25vw;
        }

        @include media-breakpoint-up(xxl) {
            height: 20vw;
        }

        #{$elm}__title {
            margin-bottom: .75rem;
        }

        #{$elm}__breadcrumb {
            margin-bottom: 0;
        }

        #{$elm}__bg {
            &::after {
                background: $gradient-black-up;
                opacity: .75;
            }
        }
    }

    &--service {
        #{$elm}__logo {
            height: 2.5rem;
            font-size: 2.5rem;
            display: inline-flex;
            margin-bottom: 1.5rem;
            margin-left: .625em;

            @include media-breakpoint-up(md) {
                height: 3rem;
                font-size: 3rem;
                margin-bottom: 2rem;
            }

            @include media-breakpoint-up(lg) {
                height: 4rem;
                font-size: 4rem;
                margin-bottom: 2.5rem;
            }

            @include media-breakpoint-up(xl) {
                height: 5rem;
                font-size: 5rem;
            }

            svg {
                height: 2.5rem;
                width: 4.875rem;

                @include media-breakpoint-up(md) {
                    height: 3rem;
                    width: 5.85rem;
                }

                @include media-breakpoint-up(lg) {
                    height: 4rem;
                    width: 8.8rem;
                }

                @include media-breakpoint-up(xl) {
                    height: 5rem;
                    width: 9.75rem;
                }
            }
        }
    }
}
