.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -.375rem;
    margin-left: -.375rem;

    /* stylelint-disable */
    > .col,
    > [class*="col-"] {
        padding-right: .375rem;
        padding-left: .375rem;
    }
    /* stylelint-enable */
}