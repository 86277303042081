.home-reviews {
    position: relative;

    &__total, &__review {
        box-shadow: $shadow-default;
    }

    &__total {
        background: map-deep-get($colors, "brand", "primary");
        margin-top: 1.25rem;
        padding: 1.5rem 2rem;

        @include media-breakpoint-up(md) {
            padding: 2rem 2.5rem;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__col {
        &:nth-of-type(2) {
            display: none;

            @include media-breakpoint-up(sm) {
                display: block;
            }
        }
    }

    &__review {
        @extend .before-hover-shadow;
        display: block;
        background: map-deep-get($colors, "black", "default");
        padding: 1.5rem 2rem;
        transition: $transition-default;

        @include media-breakpoint-up(sm) {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        @include media-breakpoint-up(md) {
            padding: 2rem 2.5rem;
        }

        &:hover {
            text-decoration: none;
            transform: scale(1.025);
        }
    }

    &__text {
        @extend .italic;
        @extend .intro;
        flex-grow: 1;
        width: 100%;
    }

    &__stats {
        flex-grow: 1;
        width: 100%;
    }

    &__stars {
        display: flex;
        margin-bottom: 1.5rem;
        width: 100%;
    }

    &__star {
        margin-right: .25rem;

        svg {
            fill: map-deep-get($colors, "brand", "primary");
        }
    }

    &__person {
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        width: 100%;
        font-size: 0.875rem;
        color: map-deep-get($colors, "gray", "medium");
    }

    &__grade {
        display: block;
        font-size: 4rem;
        line-height: 1;
        margin-bottom: 1rem;
        font-style: italic;
        font-weight: bold;
    }

    &__graphic {
        position: absolute;
        top: 3.75rem;
        right: 0;
        width: 13rem;
        height: 12.5rem;

        @include media-breakpoint-up(md) {
            height: 25rem;
            width: 26rem;
        }

        svg {
            height: 100%;
            width: 100%;
            opacity: 0.15;
            fill: map-deep-get($colors, "black", "default");
        }
    }
}
