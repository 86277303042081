.modal {
    &__wrapper {
        background: rgba(48, 48, 54, 0.8);
        display: none;
        height: 100vh;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 1000;
        overflow: auto;
    }

    &--active {
        display: flex;
        justify-content: center;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__title {
        border-bottom: 1px dotted;
        color: #ffffff;
        font-size: 1rem;
        padding-bottom: 1rem;
    }

    &__content {
        background-color: #18181b;
        color: #ffffff;
        margin: 1.75rem auto;
        width: 671px;

        @include media-breakpoint-down(md) {
            width: 550px;
        }

        @include media-breakpoint-down(sm) {
            width: 300px;
        }

        i {
            color: #ffffff;
        }
    }

    &__close {
        color: #ffffff;
        cursor: pointer;
        float: right;
        font-size: 28px;
        font-weight: 700;
        height: 30px;
        line-height: 29px;
        text-align: center;
        vertical-align: middle;
        width: 30px;
        position: absolute;
        top: 31px;
        right: 24px;

        @include media-breakpoint-up(lg) {
            top: 35px;
            right: 20px;
        }
        @include media-breakpoint-down(sm) {
            top: 0;
            right: 30px;
        }

        &:hover, &:focus {
            background: #303036;
            border-radius: 50%;
            color: #ffffff;
            text-decoration: none;
            cursor: pointer;
        }
    }

    &__image {
        display: flex;
        justify-content: center;
        margin-bottom: 0.5rem;

        img {
            width: 671px;

            @include media-breakpoint-down(md) {
                width: 550px;
            }
            @include media-breakpoint-down(sm) {
                width: 300px;
            }
        }
    }

    &__text {
        padding: 30px;
    }

    &__paragraph {
        display: flex;
        justify-content: center;
        padding-right: 30px;
        margin-bottom: 0;
    }
}
