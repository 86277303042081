.occasion-detail {
    &__title {
        @extend .italic;
        margin-bottom: 0;
    }

    &__info {
        font-size: 1.25rem;
        margin: 2rem 0;
    }

    &__price {
        font-size: 2rem;
        font-style: italic;
        line-height: 1;
        color: map-deep-get($colors, "gray", "medium");
    }

    &__information {
        padding-bottom: 3rem;

        @include media-breakpoint-up(sm) {
            padding-top: 7.5rem;
        }

        @include media-breakpoint-up(md) {
            padding-bottom: 4rem;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }

        @include media-breakpoint-up(xl) {
            padding-top: 7.5rem;
        }
    }

    &__extra {
        @extend .block-padding--bottom;
        background: map-deep-get($colors, "black", "default");
        margin-top: -1.5rem;
        padding-top: 4.5rem;

        @include media-breakpoint-up(md) {
            padding-top: 6.5rem;
            margin-top: -2.5rem;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 7.5rem;
        }
    }

    &__extra-title {
        @extend .h5;
        @extend .italic;
    }

    &__textlink {
        @extend .textlink;
        @extend .textlink--back;
        margin-bottom: 2rem;
    }
}