.contact-review {
    &__form {
        background: map-deep-get($colors, "white", "default");
        padding: 2rem;
        color: map-deep-get($colors, "black", "default");
        margin-bottom: 1.25rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        a:hover {
            color: map-deep-get($colors, "brand", "primary");
        }
    }

    &__form-intro {
        color: map-deep-get($colors, "black", "default");
        font-size: 1.25rem;
    }

    &__form-title {
        @extend .h4;
        @extend .italic;
        margin-bottom: .75rem;
    }

    &__reviews {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__total {
        flex-grow: 1;
        margin-top: 1.25rem;
        background: map-deep-get($colors, "brand", "primary");
        padding: 1.5rem 2rem;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
            align-items: center;
        }

        @include media-breakpoint-up(md) {
            padding: 2rem 2.5rem;
        }
    }

    &__grade {
        @extend .h1;
        @extend .italic;
        font-size: 7.5rem;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
            margin-right: 2rem;
        }

        @include media-breakpoint-up(lg) {
            margin-right: 3rem;
        }
    }

    &__paragraph {
        @extend .intro;
        color: map-deep-get($colors, "white", "default");
        margin-bottom: 1rem;
    }
}