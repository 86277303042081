.occasion-specs {
    margin-top: 2rem;

    @include media-breakpoint-up(md) {
        margin-top: 0;
        border-left: 1px solid map-deep-get($colors, "black", "default");
        padding: 2rem;
    }

    &__spec {
        display: flex;
        align-items: center;
        padding: 0.2rem;
    }

    &__label {
        display: block;
        width: 50%;
        font-size: 0.8rem;
        line-height: 1;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        font-weight: bold;
    }

    &__value {
        display: block;
        width: 50%;
        font-size: 1.25rem;
        line-height: 1;
        font-weight: normal;
    }

    .carmeleon-modal {
        margin-top: 2rem;
    }
}