﻿/// <reference path="../_reference.scss" />

*, *::before, *::after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

html, body {
    margin: 0 auto;
    padding: 0;
    color: map-deep-get($colors, "white", "default");
    font-family: $font-family-base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    overflow-x: hidden;

    @include media-breakpoint-up(xxl) {
        font-size: 18px;
    }
}

body {
    height: auto;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

a {
    text-decoration: none;
    transition: $transition-default;

    &:hover {
        color: map-deep-get($colors, "white", "default");
    }
}

b, strong {
    font-weight: 700;
}

::selection {
    background-color: map-deep-get($colors, "brand", "primary");
    color: map-deep-get($colors, "white", "default");
}

.h-100 {
    height: 100%;
}

@include media-breakpoint-up(md) {
    .h-md-auto {
        height: auto;
    }
}
.container {
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    @include media-breakpoint-up(sm) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    @include media-breakpoint-up(xl) {
        padding-left: .75rem;
        padding-right: .75rem;
    }
}