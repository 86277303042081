.navigation {
    position: relative;
    $elm: &;
    padding: 20px 0;
    display: flex;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
        padding: 0;
        margin-left: 2rem;
        height: auto;
        display: inline-block;
        flex-wrap: unset;
        width: auto;
    }

    @include media-breakpoint-up(xl) {
        margin-left: 3rem;
    }

    &__wrapper {
        transition: all ease-in-out 0s 1s;
        height: 0;
        overflow: hidden;
        display: flex;
        overflow-y: scroll;
        scroll-behavior: smooth;

        @include media-breakpoint-up(md) {
            height: auto;
            overflow: visible;
        }

        &--active {
            transition: all ease-in-out 0s 0s;
            margin-top: -#{map-get($header-height, "small")};
            padding-top: #{map-get($header-height, "small")};
            height: 100%;
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;
            padding-top: 0;
        }
    }

    &__list {
        width: 100%;
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
            width: auto;
            margin-bottom: 0;
        }
    }

    &__list-item {
        transition: all $transition-default-time #{$transition-default-time / 2} $transition-default-smoothing;
        opacity: 0;
        display: block;
        position: relative;
        text-align: center;

        &--has-sub {
            #{$elm}__item {
                display: none;
            }

            #{$elm}__list {
                #{$elm}__item {
                    display: inline-block;
                }
            }
        }

        @include media-breakpoint-up(md) {
            padding: 0;
            margin: 0 10px;
            opacity: 1;
            display: inline-block;
            text-align: left;

            #{$elm}__item {
                display: block;
            }

            &--has-sub{
                position: relative;
                padding-left: 20px;
                display: inline-block;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 14px;
                    height: 14px;
                    margin-top: -7px;
                    background-image: url("/images/icons/arrow_down__white.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;

                    svg {
                        fill: map-deep-get($colors, "white", "default");
                    }
                }

                &:hover {
                    #{$elm}__item {
                        background: transparent;
                        color: map-deep-get($colors, "white", "default");
                        text-decoration: none;
                    }

                    #{$elm}__list--sub {
                        opacity: 1;
                        pointer-events: initial;
                        transform: translateY(0);
                    }
                }
            }
        }

        @include media-breakpoint-up(xl) {
            margin: 0 20px;
        }
    }

    &__item {
        position: relative;
        display: inline-block;
        line-height: 1.5;
        font-size: 1.25rem;
        font-style: italic;
        font-weight: 900;
        color: map-deep-get($colors, "white", "default");
        white-space: nowrap;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            text-align: left;
            font-size: 1rem;
            font-style: normal;
            line-height: 4rem;
            font-weight: 400;
        }

        &:hover {
            color: map-deep-get($colors, "white", "default");
            text-decoration: none;
            opacity: 1;
        }
    }

    &__list--sub {
        display: block;
        line-height: 1.5;
        margin-bottom: .5rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            position: absolute;
            box-shadow: $shadow-hover;
            transition: $transition-default;
            top: 100%;
            left: 0;
            opacity: 0;
            pointer-events: none;
            transform: translateY(3rem);
            background: map-deep-get($colors, "black", "default");
            padding: 2rem 4rem 2rem 2rem;
        }

        #{$elm}__list-item {
            position: relative;
            display: block;
            line-height: 1.5;
            margin: 0;
            width: 100%;

            #{$elm}__item {
                position: relative;
                line-height: 1.5;
                width: 100%;

                &::after {
                    content: "";
                    position: absolute;
                    right: -30px;
                    top: 50%;
                    width: 20px;
                    height: 20px;
                    margin-top: -10px;
                    background-image: url("/images/icons/arrow_right__white.svg");
                    transition: 0.3s ease;
                    opacity: 0;
                }
            }

            &:hover {
                opacity: 1;
            }
        }

        #{$elm}__item {
            position: relative;
            color: map-deep-get($colors, "white", "default");
            padding: 0.25rem 0;
            opacity: 0.5;
            font-size: 1.125rem;
            font-style: initial;
            font-weight: 400;

            @include media-breakpoint-up(md) {
                font-size: 1rem;
            }

            &:hover {
                opacity: 1;

                &::after {
                    transform: translateX(10px);
                    opacity: 1;
                }
            }
        }

        #{$elm}__title {
            opacity: 1;
            font-size: 1.25rem;
            line-height: 1.5;
            font-weight: 900;
            font-style: italic;

            @include media-breakpoint-up(md) {
                font-weight: 700;
                font-size: .875rem;
                letter-spacing: .1em;
                font-style: normal;
                text-transform: uppercase;
                display: inline-block;
            }
        }
    }

    &--active {
        #{$elm}__list-item {
            opacity: 1;
            padding: 5px 0;
        }
    }
}
