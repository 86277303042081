.home-services {
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include media-breakpoint-up(md) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    &__header {
        margin-bottom: 7rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 4rem;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 5rem;
        }
    }

    &__title {
        @extend .h3;
        @extend .italic;
        margin-bottom: .5rem;
    }

    &__intro {
        @extend .intro;
        margin-bottom: 0;
        margin-top: 1.5rem;
    }
}
