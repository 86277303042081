.image-slider {
    position: relative;

    &__main {
        height: 75vw;
        opacity: 0.01;
        transition: $transition-default;

        &.flickity-enabled {
            opacity: 1;
        }

        @include media-breakpoint-up(lg) {
            height: 66.667vw;
        }

        @include media-breakpoint-up(xl) {
            height: 50vw;
        }

        .flickity-viewport {
            height: 100%;
        }

        .flickity-button {
            @extend .button;
            @extend .button--white;
            @extend .button--badge;
            height: auto;
            width: auto;
            position: absolute;
            transform: translate3d(0, -50%, 0);

            &:hover {
                transform: translate3d(0, -50%, 0) scale(1.025);
            }
        }

        .flickity-button-icon {
            position: static;
            left: auto;
            top: auto;
        }
    }

    &__main_slide {
        width: 100%;
        height: 100%;

        img {
            background: map-deep-get($colors, "black", "default");
            width: 100%;
            height: 100%;
            object-fit: contain;
            font-family: "object-fit: contain;";
        }
    }

    &__thumbs {
        position: relative;
        opacity: 0.01;
        transition: $transition-default;

        &.flickity-enabled {
            opacity: 1;
        }

        @include media-breakpoint-up(sm) {
            position: absolute;
            top: 100%;
            width: 100%;
            transform: translate3d(0, -50%, 0);
        }
    }

    &__thumbs_slide {
        position: relative;
        width: 33.333%;
        padding-top: 25%;
        margin: 20px 20px 20px 0;
        box-shadow: $shadow-light;
        outline: none;
        border: none;
        //transition: $transition-default;

        @include media-breakpoint-up(sm) {
            width: 25%;
            padding-top: 18.75%;
        }

        @include media-breakpoint-up(md) {
            width: 20%;
            padding-top: 15%;
        }

        @include media-breakpoint-up(lg) {
            width: 12.5%;
            padding-top: 9.375%;
        }

        @include media-breakpoint-up(xl) {
            width: 10rem;
            padding-top: 7.5rem;
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            object-fit: cover;
            font-family: "object-fit: cover;";
            width: 100%;
            height: 100%;
            outline: none;
            border: none;
        }

        &.is-nav-selected {
            transform: scale(1.05);
        }
    }
}
