.widget {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    padding-top: 70px;
    display: flex;
    //align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease;
    z-index: 99;
    overflow: auto;
    max-height: 100vh;

    p {
        color: #000000;
    }

    &.widget--open {
        opacity: 1;
        pointer-events: initial;
    }

    .widget__container {
        position: relative;
    }

    .widget__close {
        position: absolute;
        top: 8px;
        left: 50%;
        z-index: 99;
        transform: translate(-50%, 0);
    }

    #edw-calculator {
        color: black;

        h3 {
            font-size: 1.2em;
        }
    }
}
