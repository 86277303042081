.team {
    &__content {
        @extend .block-padding;
    }

    &__textimage {
        position: relative;
        padding: 10rem 0 2.5rem;
        margin-top: -2.5rem;

        @include media-breakpoint-up(sm) {
            height: 75vw;
            padding: 5rem 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        @include media-breakpoint-up(md) {
            height: 66.667vw;
        }

        @include media-breakpoint-up(lg) {
            height: 56.25vw;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        img, video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: "object-fit: cover;";
        }

        &::before {
            content: "";
            background: $gradient-black-up;
            position: absolute;
            opacity: .75;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
}
