// Rich text styling layout
/* stylelint-disable */
.rich-text-area {
    color: map-deep-get($colors, "gray", "light");
    position: relative;
    width: 100%;
    z-index: 1;

    p:last-child {
        margin-bottom: 0;
    }

    .intro {
        @extend .intro;
    }

    h2 {
        @extend .h4;
        text-transform: none;
    }

    h3 {
        @extend .h5;
    }

    h4 {
        @extend .h6;
    }

    h2, h3, h4 {
        @extend .italic;
        margin-bottom: .75rem;
        color: map-deep-get($colors, "white", "default");
    }

    .quote {
        font-size: 2rem;
        font-weight: 300;
        line-height: 1;
        margin-top: 1.5rem;
        margin-bottom: 3rem;
        color: map-deep-get($colors, "brand", "secondary-light");

        @include media-breakpoint-up(md) {
            font-size: 2.5rem;
        }

        @include media-breakpoint-up(lg) {
            margin-left: -3rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 3rem;
        }
    }

    img {
        width: 100%;
        height: auto;
        border-radius: $border-radius;
        box-shadow: $shadow-default;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
            width: 110%;
        }
    }

    a:not(.button-primary):not(.button-secondary):not(.button-white):not(.textlink-rta) {
        text-decoration: underline;
        font-weight: 600;
        color: map-deep-get($colors, "gray", "light");
    }

    a.textlink-rta {
        @extend .textlink !optional;
        margin-right: .75rem;

        &::after {
            content: "";
            display: inline-block;
            position: relative;
            top: .375rem;
            transition: $transition-default;
            background: url("../images/icons/arrow_right__red.svg");
            background-size: cover;
            height: 1.5rem;
            width: 1.5rem;
            margin-left: 1rem;
            margin-right: 0;
        }

        &:hover {
            text-decoration: none;

            &::after {
                margin-left: 1.25rem;
                margin-right: -.25rem;
            }
        }
    }

    ul {
        margin: 1.25rem 0;

        li {
            position: relative;
            padding-left: 1.25rem;
            margin-top: .25rem;
            margin-bottom: .25rem;

            &::before {
                content: "";
                background: map-deep-get($colors, "brand", "primary");
                width: 0.375rem;
                height: 0.375rem;
                border-radius: 50%;
                position: absolute;
                transform: translateY(-50%);
                left: 0;
                top: .75rem;
            }
        }

        ul, ol {
            margin: 0;
        }
    }

    ol {
        margin: 1.25rem 0;
        padding: 0;
        list-style: none;
        counter-reset: inst;

        li {
            counter-increment: inst;
            position: relative;
            padding-left: 1.25rem;
            margin-top: .25rem;
            margin-bottom: .25rem;

            &::before {
                content: counter(inst);
                display: inline-block;
                position: absolute;
                font-weight: 700;
                font-size: .875rem;
                line-height: 1.5rem;
                width: 1.25rem;
                left: 0;
                color: map-deep-get($colors, "brand", "secondary");
            }
        }

        ul, ol {
            margin: 0;
        }
    }

    .button-primary {
        @extend .button;
        @extend .button--primary;
    }

    .button-white {
        @extend .button;
        @extend .button--white;
    }

    .button-black {
        @extend .button;
        @extend .button--black;
    }
}
/* stylelint-enable */
