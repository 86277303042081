.pagination {
    margin-top: 1.25rem;
    margin-bottom: 2.5rem;
    position: relative;
    display: flex;
    justify-content: flex-end;

    &__list {
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-up(md) {
            justify-content: flex-start;
        }
    }

    &__list-item {
        display: inline-flex;
        align-items: center;
    }

    &__list-link {
        display: inline-flex;
        height: 2rem;
        width: 2rem;
        font-weight: 700;
        margin: 0 .125rem;
        font-size: .875rem;
        color: map-deep-get($colors, "gray", "light");
        align-items: center;
        justify-content: center;
        border-radius: $border-radius;
        transition: $transition-default;
        text-decoration: none;

        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }

        &:hover {
            background: transparent;
            color: map-deep-get($colors, "white", "default");
            text-decoration: none;
        }

        &--active {
            background: map-deep-get($colors, "black", "default");
            color: map-deep-get($colors, "white", "default");

            &:hover {
                background: map-deep-get($colors, "black", "default");
                color: map-deep-get($colors, "white", "default");
                opacity: .8;
            }
        }
    }

    &__control-link {
        @extend .textlink;
        color: map-deep-get($colors, "white", "default");

        &--prev {
            @extend .textlink--back;
            margin-right: 1.5rem;

            svg {
                fill: map-deep-get($colors, "white", "default");
            }
        }

        &--next {
            @extend .textlink--icon-right;
            margin-left: 1.5rem;

            svg {
                fill: map-deep-get($colors, "white", "default");
            }
        }

        &--inactive {
            pointer-events: none;
            opacity: .3;
        }
    }
}
