.textlink {
    background: none;
    border: none;
    color: map-deep-get($colors, "brand", "primary");
    transition: $transition-default;
    line-height: 1.5rem;
    font-size: .875rem;
    font-weight: 900;
    padding: 0;
    letter-spacing: .025em;
    width: auto;
    text-align: center;
    display: inline-block;
    align-items: center;
    position: relative;
    text-decoration: none;

    svg {
        transition: $transition-default;
        fill: map-deep-get($colors, "brand", "primary");
        height: 1.5rem;
        width: 1.5rem;
        position: relative;
        transform: translateY(-1px);
    }

    &:hover {
        text-decoration: none;
        color: map-deep-get($colors, "brand", "primary");
    }

    &--white {
        color: map-deep-get($colors, "white", "default");

        &:hover {
            color: map-deep-get($colors, "white", "default");
        }

        svg {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &--black {
        color: map-deep-get($colors, "black", "default");

        &:hover {
            color: map-deep-get($colors, "black", "default");
        }

        svg {
            fill: map-deep-get($colors, "black", "default");
        }
    }

    &--icon-left {
        svg {
            margin-left: 0;
            margin-right: 1rem;
        }

        &:hover {
            svg {
                margin-left: .25rem;
                margin-right: .75rem;
            }
        }
    }

    &--icon-right {
        svg {
            margin-left: 1rem;
            margin-right: 0;
        }

        &:hover {
            svg {
                margin-left: 1.25rem;
                margin-right: -.25rem;
            }
        }
    }

    &--back {
        svg {
            margin-left: 0;
            margin-right: 1rem;
        }

        &:hover {
            svg {
                margin-left: -.25rem;
                margin-right: 1.25rem;
            }
        }
    }

    &--down {
        svg {
            margin-left: 0;
            margin-right: 1rem;
            top: 0;
        }

        &:hover {
            svg {
                top: .25rem;
            }
        }
    }

    &--up {
        svg {
            margin-left: 0;
            margin-right: 1rem;
            top: 0;
        }

        &:hover {
            svg {
                top: -.25rem;
            }
        }
    }

    &--scale {
        svg {
            margin-left: 0;
            margin-right: 1rem;
        }

        &:hover {
            svg {
                margin-left: 0;
                margin-right: 1rem;
                transform: scale(1.1);
            }
        }
    }
}