.occasion-filter {
    position: relative;
    margin-bottom: 2.5rem;

    &__body {
        background: map-deep-get($colors, "gray", "extra-dark");
        padding: 1.5rem;

        @include media-breakpoint-up(md) {
            padding: 2rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 2.5rem;
        }
    }

    &__titel {
        margin: 0 0 1.5rem;
        font-size: 1.5rem;
        font-style: italic;
    }

    &__label {
        font-size: 0.8rem;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 1.25rem;

        @media screen and (min-width: 500px) {
            justify-content: space-between;
            flex-flow: row wrap;
        }
    }

    &__button {
        margin: 0 0 .5rem;

        @media screen and (min-width: 500px) {
            margin: 0;
        }
    }
}
