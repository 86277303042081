.occasion-card {
    @extend .before-hover-shadow;
    display: block;
    margin-bottom: 1.25rem;
    background: map-deep-get($colors, "black", "default");
    box-shadow: $shadow-default;
    transition: $transition-default;
    text-decoration: none;

    @include media-breakpoint-up(sm) {
        height: 100%;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
    }

    &:hover {
        transform: scale(1.025);
        text-decoration: none;
        background: map-deep-get($colors, "gray", "dark");
    }

    &__image {
        position: relative;
        padding-top: 75%;
        background-size: cover;
    }

    &__image-label {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2rem;
        width: auto;
    }

    &__title {
        @extend .h5;
        margin-bottom: 1rem;
    }

    &__paragraph {
        min-height: 4.5rem;
    }

    &__content {
        padding: 1.5rem 2rem;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        color: map-deep-get($colors, "gray", "light");

        @include media-breakpoint-up(md) {
            padding: 2rem 2.5rem;
        }
    }

    &__specs {
        flex-grow: 1;
        margin-bottom: 1.5rem;
    }

    &__spec {
        display: flex;
    }

    &__label {
        font-weight: bold;
        width: 50%;
    }

    &__price {
        color: map-deep-get($colors, "gray", "medium");
        font-size: 2rem;
        font-style: italic;
        line-height: 1;
        margin: 0;
    }
}
